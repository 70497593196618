const initialState = {
    data: [],
    total: 1,
    params: {},
    allData: [],
    passengerData: [],
    speedData: [],
    machineData: [],
    getPassengerData: []

}

const machineFormData = (state = initialState, action) => {
    switch (action.type) {
        case 'GET_DATA_MACHINE_FORM':
            return {
                ...state,
                allData: action.allData,
                data: action.data,
                total: action.totalPages,
                params: action.params,
                passengerData: []
            }
        case 'CREATE_MACHINE_FORM':
            return { ...state }
        case 'UPDATE_MACHINE_FORM':
            return { ...state }
        case 'GET_DATA_PASSENGER':
            return {
                ...state,
                passengerData: action.passengerData
            }
        case 'GET_DATA_SPEED':
            return {
                ...state,
                speedData: action.speedData
            }
        case 'GET_DATA_MACHINE_MASTER':
            return {
                ...state,
                machineData: action.machineData
            }
        case 'GET_DATA_EXCEL':
            return { ...state }
        default:
            return { ...state }
    }
}

export default machineFormData
