// ** UseJWT import to get config
import {Fragment } from "react";
import Avatar from "@components/avatar";
import { toast, Slide } from "react-toastify";
import { firebase, auth } from "./../../../base";
import { Axios, AxiosInstance } from '@utils'

export const getDNSDataByUrl = (user_id, setLoader) => {
  return dispatch => {
    setLoader(true)
    AxiosInstance.get(`/api/users/${user_id}`).then(response => {
    dispatch({
      type:"GET_DNS_DATA",
      data: response?.data?.data
    })
    }).catch(error=>{
      console.log("!!!!error in users:", error);
      dispatch({
        type:"GET_DNS_DATA",
        data: {}
      })
    })
    .finally(() =>{
      setLoader(false)
    })
  }
}