const initialState = {
    data: [],
    total: 1,
    params: {},
    allData: [],
    cityListData: [],
    RepairOrderReportDataByID: [],
    materialAndSpecificationInfoById: [],
    branchListData: []

}

const rapairReportReducer = (state = initialState, action) => {
    switch (action.type) {

        case 'GET_REPAIR_REPORT':
            return {
                ...state,
                allData: action.allData,
                data: action.data,
                total: action.totalPages,
                params: action.params,
                statusCounts: action.statusCounts
            }
        case 'GET_CITY_LIST':
            return {
                ...state,
                cityListData: action.cityListData
            }
        case 'GET_REPAIR_PORT_DATA':
            return {
                ...state,
                RepairOrderReportDataByID: action.RepairOrderReportDataByID
            }

        case 'GET_MATERIAL_AND_SPECIFICATION_DATA':
            return {
                ...state,
                materialAndSpecificationInfoById: action.data
            }
        case 'GET_BRANCH_LIST_REPAIR':
            return {
                ...state,
                branchListData: action?.branchListData
            }

        default:
            return { ...state }
    }
}
export default rapairReportReducer
